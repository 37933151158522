import React from 'react';
import AppStoreLogo from '../assets/platform/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import i18n from '../i18n';
import './Connect.css';
import styled from 'styled-components';
import Downloads from './Downloads';

const Button = styled.button`
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  border-color: yellow;
  border-width: medium;
  margin: 10px 0px;
  cursor: pointer;
  &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: default;
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ uri, desktopVersion, mobileVersion }: { desktopVersion: string; mobileVersion: string; uri: string }) => {
  return (
    <div id="intro" className="intro">
      <h3>
        {i18n.t('Wallet3 Connect')}
        <br />
      </h3>

      {i18n.t(
        "connect-desc"
      )}

      <a
        style={{ marginTop: '24px', marginBottom: '24px' }}
        className="connect-button"
        href={`${uri}`}
        title="Wallet3 Connect"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button> {i18n.t('Connect')} </Button>
      </a>

      <Downloads mobileVersion={mobileVersion} desktopVersion={desktopVersion} />
    </div>
  );
};
