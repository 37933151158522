import './Features.css';

import React, { useState } from 'react';

import AndroidApkLogo from '../assets/platform/apk.svg';
import AppStoreLogo from '../assets/platform/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import Feather from 'feather-icons-react';
import GooglePlayLogo from '../assets/platform/Google_Play_Store_badge_EN.svg';
import MSStore from '../assets/MicrosoftStore.svg';
import TestflightLogo from '../assets/platform/testflight.svg';
import apple from '../assets/apple-w.svg';
import i18n from '../i18n';
import linux from '../assets/linux.svg';
import windows from '../assets/windows.svg';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ desktopVersion, mobileVersion }: { desktopVersion: string; mobileVersion: string }) => {
  const winX64 = `/download/${desktopVersion}/wallet3-win-x64-${desktopVersion}.exe`;
  const linuxX64 = `/download/${desktopVersion}/wallet3-linux-x86_64-${desktopVersion}.AppImage`;
  const darwinX64 = `/download/${desktopVersion}/wallet3-mac-x64-${desktopVersion}.dmg`;
  const darwinArm64 = `/download/${desktopVersion}/wallet3-mac-arm64-${desktopVersion}.dmg`;
  const apk4337 = `/download/mobile/wallet3-android-4.337.1.apk`;

  const [more, setMore] = useState(false);

  return (
    <div id="downloads" className="downloads">
      {more && (
        <div className="title" style={{ marginTop: 0 }}>
          <Feather icon="smartphone" size={12} /> <span>{i18n.t('Mobile')}</span>
        </div>
      )}

      <div className="mobile-stores">
        <a
          href={'https://itunes.apple.com/us/app/wallet-3/id1597395741'}
          title="App Store"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={AppStoreLogo} alt="App Store" width={120} />
        </a>
        <a
          href={'https://play.google.com/store/apps/details?id=jp.co.chainbow.wallet3.mobile'}
          title="Google Play"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={GooglePlayLogo} alt="Google Play" width={133} />
        </a>

        {more && (
          <a
            href={'https://testflight.apple.com/join/5acvlgyK'}
            title="TestFlight"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={TestflightLogo} alt="TestFlight"  style={{height:30}}/>
          </a>
        )}

        {more && (
          <a href={`/download/mobile/wallet3-android-${mobileVersion}.apk`} title="Android APK">
            <img src={AndroidApkLogo} alt="Android APK" />
          </a>
        )}

        <span id="moreOrLess" onClick={() => setMore(!more)} style={{ marginTop: -4 }}>
          {more ? i18n.t('Less') : i18n.t('More')}
          <Feather icon={more ? 'chevron-up' : 'chevron-down'} size={7} style={{ marginLeft: 2 }} />
        </span>
      </div>

      {/* {more && (
        <div className="title">
          <Feather icon="monitor" size={12} /> <span>{i18n.t('Desktop')}</span>
        </div>
      )}

      {more && (
        <div className="desktop">
          <div className="apple">
            <img src={apple} alt="Apple Silicon" />
            <a href={darwinArm64} download title="Download macOS dmg File">
              Apple Silicon
            </a>
            <a href={darwinX64} download title="Download macOS dmg File">
              Intel x64
            </a>
          </div>
          <a href={'https://www.microsoft.com/store/apps/9NH37ZC5745R'} target="_blank" rel="noopener noreferrer">
            <img src={MSStore} alt="Microsoft Store" title="Get it from Microsoft Store" />
          </a>

          <a href={winX64} download>
            <img
              src={windows}
              alt="windows"
              title="Download Windows Setup File"
              style={{ width: 27, marginLeft: 10, marginRight: 8 }}
            />
          </a>

          <a href={linuxX64} download>
            <img src={linux} alt="linux" title="Download Linux AppImage File" style={{ width: 27 }} />
          </a>
        </div>
      )} */}
      {/* <hr></hr> */}

      {/* <div><strong >{"We are currently attending ETHGlobal Tokyo and have provided temporary preview versions of the iOS and Android conference apps below. The official version can be downloaded from Google Play and the App Store."}</strong></div>
      <div>{"Account Abstraction(ERC4337) Wallet 3 v4.337.1 Test Version."}</div>
      <div style={{ color:"red" }}>{"Preview purposes only"}</div>

<a href={'https://testflight.apple.com/join/5acvlgyK'} title="TestFlight" target="_blank" rel="noopener noreferrer">
        <img src={TestflightLogo} alt="TestFlight" />
      </a>

      <a href={apk4337} title="Android APK">
        <img src={AndroidApkLogo} alt="Android APK" />
      </a> */}

    </div>
  );
};
