import './Footer.css';
import { Link } from 'react-router-dom';

import discord from '../assets/social/discord.svg';
import github from '../assets/social/github.svg';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <footer>
      <div className="container">
        <div>
          &copy; 2021-{new Date().getFullYear()} &nbsp; <a href="https://chainbow.co.jp">ChainBow Co, Ltd.</a>
          <span className="company-address"> 2-6-6-404, Taito, Taito-Ku, Tokyo, 110-0016, Japan.</span>
        </div>

        <div className="social">
          <a href="https://discord.gg/xaaUbPBFxM">
            <img src={discord} alt="Discord" style={{ height: 24, marginTop: 2 }} />
          </a>

          <a href="https://github.com/wallet3">
            <img src={github} alt="Github" />
          </a>

          <a className="brand-kit" href="/brand.zip">
            Brand Kit
          </a>

          <Link to="/terms">Terms & Privacy</Link>
        </div>
      </div>
    </footer>
  );
};
