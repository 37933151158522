import './Features.css';

import React, { useState } from 'react';

import AndroidApkLogo from '../assets/platform/apk.svg';
import AppStoreLogo from '../assets/platform/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import Feather from 'feather-icons-react';
import GooglePlayLogo from '../assets/platform/Google_Play_Store_badge_EN.svg';
import MSStore from '../assets/MicrosoftStore.svg';
import TestflightLogo from '../assets/platform/testflight.svg';
import apple from '../assets/apple-w.svg';
import i18n from '../i18n';
import linux from '../assets/linux.svg';
import windows from '../assets/windows.svg';
import Downloads from './Downloads';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ desktopVersion, mobileVersion }: { desktopVersion: string; mobileVersion: string }) => {
  return (
    <div id="intro" className="intro">
      <h3>
        {i18n.t('intro')}
        <br />
        {i18n.t('subintro')}
      </h3>

      <ul>
        <li>
          <Feather icon="shield" size={19} /> {i18n.t('feature1')}
        </li>
        <li>
          <Feather icon="box" size={19} />
          {i18n.t('feature2')}
        </li>
        <li>
          <Feather icon="compass" size={19} /> {i18n.t('feature3')}
        </li>
        <li>
          <Feather icon="smile" size={19} /> {i18n.t('feature4')}
        </li>
        <li>
          <Feather icon="share-2" size={19} /> {i18n.t('feature5')}
        </li>
        <li>
          <Feather icon="gift" size={19} /> {i18n.t('feature6')}
        </li>
        {/* <li>
          <Feather icon="link-2" size={19} /> {i18n.t('feature2')}
        </li>
        <li>
          <Feather icon="cpu" size={19} /> {i18n.t('feature3')}
        </li>
        <li>
          <Feather icon="shield" size={19} /> {i18n.t('feature4')}
        </li>
        <li>
          <Feather icon="smile" size={19} /> {i18n.t('feature5')}
        </li> */}
      </ul>

      <Downloads mobileVersion={mobileVersion} desktopVersion={desktopVersion} />
    </div>
  );
};
