import Feather from 'feather-icons-react';
import React from 'react';
import i18n from '../../i18n';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  return (
    <div className="security-first section" style={{ marginTop: 81 }}>
      <div
        style={{
          position: 'relative',
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        <Feather icon="shield" size={167} strokeWidth={1} color={'#2ecc71'} fill="#2ecc71" />
        <Feather icon="check" size={64} color="#fff" style={{ position: 'absolute' }} />
      </div>

      <div className="text">
        <h1>{i18n.t('security-title')}</h1>
        <p>{i18n.t('security-desc1')}</p>
        <p>{i18n.t('security-desc2')}</p>
        <p>{i18n.t('security-desc3')}</p>
      </div>
    </div>
  );
};
