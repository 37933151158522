import React from 'react';
import arbitrum from '../../assets/chains/arbitrum.svg';
import aurora from '../../assets/chains/aurora.svg';
import avalanche from '../../assets/chains/avalanche.svg';
import bsc from '../../assets/chains/bsc.svg';
import celo from '../../assets/chains/celo.svg';
import fantom from '../../assets/chains/ftm.svg';
import findora from '../../assets/chains/findora.svg';
import harmony from '../../assets/chains/harmony-one.svg';
import heco from '../../assets/chains/heco.svg';
import klaytn from '../../assets/chains/klaytn.svg';
import moonbeam from '../../assets/chains/moonbeam.svg';
import oec from '../../assets/chains/oec.svg';
import optimism from '../../assets/chains/optimism.svg';
import polygon from '../../assets/chains/polygon.svg';
import xdai from '../../assets/chains/xdai.svg';
import zksync from '../../assets/chains/zksync.svg';
import i18n from '../../i18n';

const chains = [
  { url: 'https://zksync.io', img: zksync, alt: 'zkSync' },
  { url: 'https://arbitrum.io', img: arbitrum, alt: 'Arbitrum' },
  { url: 'https://optimism.io', img: optimism, alt: 'Optimism' },
  { url: 'https://polygon.technology', img: polygon, alt: 'Polygon' },
  { url: 'https://www.bnbchain.world', img: bsc, alt: 'BNBChain' },
  { url: 'https://www.xdaichain.com', img: xdai, alt: 'Gnosis Chain' },
  { url: 'https://aurora.dev', img: aurora, alt: 'Aurora' },
  { url: 'https://harmony.one', img: harmony, alt: 'Harmony' },
  { url: 'https://moonbeam.network', img: moonbeam, alt: 'Moonbeam' },
  { url: 'https://celo.org', img: celo, alt: 'Celo' },
  { url: 'https://fantom.foundation', img: fantom, alt: 'Fantom' },
  { url: 'https://avax.network', img: avalanche, alt: 'Avalanche' },
  { url: 'https://www.hecochain.com', img: heco, alt: 'HECO' },
  { url: 'https://findora.org', img: findora, alt: 'Findora' },
  { url: 'https://www.klaytn.foundation', img: klaytn, alt: 'Klaytn' },
  { url: 'https://www.okx.com/oec', img: oec, alt: 'OEC' },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  return (
    <div className="layer2 section">
      <div className="items reverse">
        {chains.map((chain) => {
          return (
            <a key={chain.url} href={chain.url} target="_blank" rel="noreferrer" title={chain.alt}>
              <img src={chain.img} alt={chain.alt} />
            </a>
          );
        })}
      </div>

      <div className="text">
        <h1>{i18n.t('chain-title')}</h1>
        <p>{i18n.t('chain-desc')}</p>
      </div>
    </div>
  );
};
