import './AppShow.css';

import React from 'react';
// import app from '../assets/app/app.png';
// import connect from '../assets/app/connect.png';
import mobile from '../assets/app/mobile.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <div className="app-show">
      <img className="mobile-main" src={mobile} alt="Wallet 3" />
    </div>
  );
};
