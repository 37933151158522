import React from 'react';
import aave from '../../assets/dapps/aave.svg';
import comp from '../../assets/dapps/comp.svg';
import crv from '../../assets/dapps/crv.svg';
import dydx from '../../assets/dapps/dydx.svg';
import foundation from '../../assets/dapps/foundation.svg';
import lido from '../../assets/dapps/lido.svg';
import mirror from '../../assets/dapps/mirror.svg';
import mkr from '../../assets/dapps/mkr.svg';
import opensea from '../../assets/dapps/opensea.svg';
import pancakeswap from '../../assets/dapps/pancakeswap.svg';
import rarible from '../../assets/dapps/rarible.svg';
import snx from '../../assets/dapps/snx.svg';
import sushi from '../../assets/dapps/sushi.svg';
import uni from '../../assets/dapps/uni.svg';
import yfi from '../../assets/dapps/yfi.svg';
import zapper from '../../assets/dapps/zapper.svg';
import i18n from '../../i18n';

const dapps = [
  { url: 'https://makerdao.com', img: mkr, alt: 'MakerDAO' },
  { url: 'https://uniswap.exchange', img: uni, alt: 'Uniswap' },
  { url: 'https://curve.fi', img: crv, alt: 'Curve' },
  { url: 'https://yearn.finance', img: yfi, alt: 'Yearn Finance' },
  { url: 'https://synthetix.io', img: snx, alt: 'Synthetix' },
  { url: 'https://sushi.exchange', img: sushi, alt: 'Sushiswap' },
  { url: 'https://compound.finance', img: comp, alt: 'Compound' },
  { url: 'https://dydx.exchange', img: dydx, alt: 'Dydx' },
  { url: 'https://aave.io', img: aave, alt: 'Aave' },
  { url: 'https://opensea.io', img: opensea, alt: 'OpenSea' },
  { url: 'https://foundation.app', img: foundation, alt: 'Foundation' },
  { url: 'https://zapper.fi', img: zapper, alt: 'Zapper' },
  { url: 'https://mirror.xyz', img: mirror, alt: 'Mirror' },
  { url: 'https://pancakeswap.finance', img: pancakeswap, alt: 'PancakeSwap' },
  { url: 'https://rarible.com', img: rarible, alt: 'Rarible' },
  { url: 'https://lido.fi', img: lido, alt: 'Lido' },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  return (
    <div className="DeFi section">
      <div className="text reverse">
        <h1>{i18n.t('web3-title')}</h1>
        <p>{i18n.t('web3-desc1')}</p>
        <p>{i18n.t('web3-desc2')}</p>
        <p>{i18n.t('web3-desc3')}</p>
        <p>{i18n.t('web3-desc4')}</p>
      </div>

      <div className="items">
        {dapps.map((dapp) => {
          return (
            <a href={dapp.url} key={dapp.url} title={dapp.alt}>
              <img src={dapp.img} alt={dapp.alt} />
            </a>
          );
        })}
      </div>
    </div>
  );
};
