import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import './Header.css';

import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';

import Feather from 'feather-icons-react';
import Logo from '../components/Logo';
import React from 'react';
import cn from '../assets/flags/cn.svg';
import ja from '../assets/flags/ja.svg';
import tw from '../assets/flags/tw.svg';
import usa from '../assets/flags/usa.svg';

const langs = [
  { title: 'English', value: 'en', flag: usa },
  { title: '日本語', value: 'jp', flag: ja },
  { title: '简体中文', value: 'zh-CN', flag: cn },
  // { title: '繁体中文', value: 'zh-CN', flag: tw },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <header>
      <nav>
        <Logo width={87} fill="#333" />

        <div className="actions" style={{ marginBottom: -3 }}>
          <Menu
            menuButton={
              <MenuButton
                style={{
                  backgroundColor: 'transparent',
                  borderWidth: 0,
                  padding: 0,
                  margin: 0,
                }}
              >
                <Feather className="navicon" icon="globe" size={24} color="#000" />
              </MenuButton>
            }
            transition
          >
            {langs.map((lang) => (
              <MenuItem
                onClick={() => {
                  localStorage.setItem('lang', lang.value);
                  window.location.reload();
                }}
              >
                <div
                  style={{
                    padding: `4px 0`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <span>{lang.title}</span>
                  <img src={lang.flag} alt={lang.title} style={{ width: 15, height: 15 }} />
                </div>
              </MenuItem>
            ))}
          </Menu>

          <a href="https://docs.wallet3.io" className="navicon">
            <Feather icon="book" size={24} />
          </a>
          <a href="https://twitter.com/wallet3_io" className="navicon" style={{ marginRight: 8 }}>
            <Feather icon="twitter" size={24} />
          </a>
        </div>
      </nav>
    </header>
  );
};
