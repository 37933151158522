import React from 'react';
import './Terms.css';

const Terms: React.FC = () => {
    return (
        <div className="container">
            <h1 className="title">Wallet3 Terms of Service and Privacy Policy</h1>
            <p className="date">Last Updated: August 9, 2024</p>
            
            <section className="section">
                <h2 className="section-title">1. Acknowledgment and Acceptance</h2>
                <p className="paragraph">1.1 By downloading, installing, or using Wallet3, or by accessing our website, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service and Privacy Policy.</p>
                <p className="paragraph">1.2 You confirm that you are of legal age in your jurisdiction to use digital wallet services and that your use of Wallet3 complies with applicable laws and regulations.</p>
                <p className="paragraph">1.3 We may update these terms from time to time, and the latest version will be effective upon publication on our website. Continued use of our services constitutes acceptance of the updated terms.</p>
            </section>

            <section className="section">
                <h2 className="section-title">2. Definitions</h2>
                <ul className="list">
                    <li className="list-item"><strong>Wallet3:</strong> A multi-chain Ethereum wallet developed by us.</li>
                    <li className="list-item"><strong>Identity:</strong> A blockchain identity generated by a pair of public and private keys.</li>
                    <li className="list-item"><strong>Wallet Password:</strong> The password you set to encrypt and secure your private key.</li>
                    <li className="list-item"><strong>Private Key:</strong> A 256-bit random bit sequence used to control your digital tokens.</li>
                    <li className="list-item"><strong>Public Key:</strong> Derived from the private key, used to generate a wallet address.</li>
                    <li className="list-item"><strong>Mnemonic Phrase:</strong> A sequence of words used to back up and recover your private key.</li>
                    <li className="list-item"><strong>Token:</strong> Digital assets supported by Wallet3.</li>
                    <li className="list-item"><strong>Personal Information:</strong> Data that includes, but is not limited to, email addresses, mobile device information, and transaction records. It does not include wallet passwords, private keys, mnemonic phrases, or keystores.</li>
                    <li className="list-item"><strong>RPC Server:</strong> Remote Procedure Call servers that Wallet3 utilizes to broadcast transactions and retrieve address and transaction information from various EVM blockchains.</li>
                </ul>
            </section>

            <section className="section">
                <h2 className="section-title">3. Wallet3 Services</h2>
                <p className="paragraph">3.1 <strong>Create or Restore Identity:</strong> You can create or restore your Identity and manage multiple blockchain wallets.</p>
                <p className="paragraph">3.2 <strong>Import Wallet:</strong> You can import wallets generated by other applications and manage supported tokens within Wallet3.</p>
                <p className="paragraph">3.3 <strong>Transfer and Receive Tokens:</strong> You can send and receive tokens by signing transactions with your private key, thus amending the blockchain ledger.</p>
                <p className="paragraph">3.4 <strong>View Quotes:</strong> You can view token prices provided by third-party services.</p>
                <p className="paragraph">3.5 <strong>Manage Tokens:</strong> You can add, manage, or remove tokens supported by Wallet3.</p>
                <p className="paragraph">3.6 <strong>Transaction Records:</strong> While we may replicate transaction records, the most accurate records are maintained on the blockchain itself.</p>
                <p className="paragraph">3.7 <strong>RPC Server Access:</strong> Wallet3 utilizes various EVM blockchain RPC servers to broadcast transactions and retrieve address and transaction information. These servers may be inaccessible or experience timeouts, and Wallet3 cannot ensure continued effectiveness under such circumstances.</p>
                <p className="paragraph">3.8 <strong>Service Suspension:</strong> We reserve the right to suspend or restrict your access to certain features if necessary.</p>
            </section>

            <section className="section">
                <h2 className="section-title">4. Your Rights and Obligations</h2>
                <p className="paragraph">4.1 <strong>Create or Import Wallet:</strong> You are responsible for securely creating and/or importing wallets and setting a strong Wallet Password.</p>
                <p className="paragraph">4.2 <strong>Security:</strong> You must secure your mobile device, Wallet Password, Private Key, Mnemonic Phrase, and Keystore. Any loss of these may result in the loss of your digital assets, for which we are not responsible.</p>
                <p className="paragraph">4.3 <strong>Transfer of Tokens:</strong> Blockchain transactions are irreversible, and you bear full responsibility for any errors in token transfers.</p>
                <p className="paragraph">4.4 <strong>Compliance:</strong> You must comply with all relevant laws and regulations when using Wallet3.</p>
            </section>

            <section className="section">
                <h2 className="section-title">5. Risks and Liability</h2>
                <p className="paragraph">5.1 <strong>Blockchain Risks:</strong> Blockchain technology is still evolving and may be subject to significant risks, including technical failures and high token volatility.</p>
                <p className="paragraph">5.2 <strong>Responsibility for Security:</strong> Wallet3 is a tool for managing tokens, and we do not store any of your private data on our servers. You are solely responsible for securing your wallet data.</p>
                <p className="paragraph">5.3 <strong>Irretrievable Loss:</strong> We are not liable for any losses resulting from lost devices, forgotten passwords, private key leaks, or hacking incidents.</p>
                <p className="paragraph">5.4 <strong>Third-Party Websites:</strong> Wallet3 may allow you to access third-party websites through its built-in browser. We are not responsible for the content, services, or security of any third-party websites, and any losses incurred through these sites are your responsibility.</p>
                <p className="paragraph">5.5 <strong>Software and Protocol Issues:</strong> We are not responsible for losses arising from issues with blockchain protocols, such as network failures or software bugs.</p>
                <p className="paragraph">5.6 <strong>RPC Server Access:</strong> The Wallet3 wallet utilizes various EVM blockchain RPC servers to broadcast transactions and retrieve address and transaction information. These servers may become inaccessible or experience timeouts due to various reasons, which can affect the effectiveness of the wallet. Wallet3 cannot ensure continued operation under these circumstances.</p>
                <p className="paragraph">5.7 <strong>Fraudulent Activities:</strong> Various fraudulent activities, such as fake airdrops, fake stablecoins, fake DeFi projects, and fake exchanges, exist in the world. Wallet3 strives to help users identify these scams, but users must be responsible for their own actions and carefully evaluate DApps before interacting with them.</p>
                <p className="paragraph">5.8 <strong>Compliance with Local Laws:</strong> You are responsible for ensuring that your use of Wallet3 complies with local laws. If your jurisdiction prohibits the use of digital wallets, you should not use Wallet3.</p>
            </section>

            <section className="section">
                <h2 className="section-title">6. Disclaimer and Limitation of Liability</h2>
                <p className="paragraph">6.1 <strong>"As-Is" Service:</strong> Wallet3 is provided "as is" and "as available." We do not guarantee that our services will be uninterrupted, secure, or error-free.</p>
                <p className="paragraph">6.2 <strong>Limitation of Liability:</strong> Our liability is limited to the fullest extent permitted by law, and we are not liable for any indirect, incidental, or consequential damages.</p>
                <p className="paragraph">6.3 <strong>No Financial Compensation:</strong> We do not provide financial compensation for any losses or damages related to the use of Wallet3.</p>
            </section>

            <section className="section">
                <h2 className="section-title">7. Privacy Policy</h2>
                <p className="paragraph">7.1 <strong>Data Collection:</strong> We collect crash reports and certain technical data through third-party analytics tools such as Google Analytics. This data includes, but is not limited to, device information, app performance data, and usage patterns.</p>
                <p className="paragraph">7.2 <strong>No Collection of Personal Information:</strong> We do not collect personally identifiable information such as your name, phone number, or address, unless voluntarily provided by you (e.g., email address).</p>
                <p className="paragraph">7.3 <strong>No Collection of Wallet Data:</strong> We do not collect or have access to your wallet passwords, private keys, mnemonic phrases, or any other sensitive wallet information.</p>
                <p className="paragraph">7.4 <strong>Standard Privacy Protections:</strong> We implement industry-standard security measures to protect the data we collect. However, no method of transmission over the internet or electronic storage is 100% secure.</p>
                <p className="paragraph">7.5 <strong>Third-Party Services:</strong> Our services may include links to third-party websites or services, which have their own privacy policies. We are not responsible for the privacy practices of these third parties.</p>
                <p className="paragraph">7.6 <strong>Data Use:</strong> The data we collect is used to improve our services, diagnose technical issues, and enhance user experience. We do not sell or share your data with third parties except as required by law.</p>
            </section>

            <section className="section">
                <h2 className="section-title">8. Miscellaneous Provisions</h2>
                <p className="paragraph">8.1 <strong>Non-Transferable Rights:</strong> You may not transfer any rights or obligations under these Terms to another person.</p>
                <p className="paragraph">8.2 <strong>Intellectual Property:</strong> All content and intellectual property associated with Wallet3 are owned by us or our licensors. Unauthorized use is prohibited.</p>
                <p className="paragraph">8.3 <strong>No Waiver:</strong> Failure to enforce any part of these Terms does not constitute a waiver of our rights.</p>
                <p className="paragraph">8.4 <strong>Entire Agreement:</strong> These Terms, together with our Privacy Policy, constitute the entire agreement between you and us and supersede all prior agreements.</p>
                <p className="paragraph">8.5 <strong>Translated Versions:</strong> In the event of any discrepancy between different language versions of these Terms, the English version shall prevail.</p>
                <p className="paragraph">8.6 <strong>Severability:</strong> If any part of these Terms is found to be unenforceable, the remaining provisions will remain in full effect.</p>
                <p className="paragraph">8.7 <strong>User Agreement:</strong> By using Wallet3, you confirm that you have read, understood, and agreed to these Terms of Service and Privacy Policy. If you do not agree, you must cease using Wallet3 immediately.</p>
            </section>
        </div>
    );
};

export default Terms;
